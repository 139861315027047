@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes rotatingText {
    0% { content: "Developer"; }
    50% { content: "Designer"; }
  }

.rotating-text::after {
    content: "Developer";
    animation: rotatingText 3s linear infinite;
  }