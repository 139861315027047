/* CustomCursor.css */

.cursor-dot {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: rgb(217, 10, 10);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: 0.1s;
  @media (max-width: 768px) {
    display: none;
  }
}

.cursor-circle {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: 0.15s;
  @media (max-width: 768px) {
    display: none;
  }
}
