.chat-icon {
  position: fixed;
  bottom: 50px;
  right: 30px;
  opacity: 100%;
  cursor: pointer; /* Optionally, set a cursor style */
}

@media (max-width: 768px) {
  /* Apply styles for screens with a maximum width of 768px */
  .chat-icon {
    position: fixed;
    bottom: 15px;
    right: 5px;
    opacity: 70%; /* Adjust opacity for smaller screens */
  }
}
